@import "../../../assets/styles/variables";
.main {
  min-height: 100vh;
}

.logo {
  height: auto;
  max-height: 6vw;
  max-width: 100%;
  filter: var(--bs-logo-menu-filter);
  margin-bottom: 1.5rem;

  @media #{$tabletPortrait} {
    margin-bottom: 0;
    max-height: 5vw;
    margin: auto;
  }
  @media #{$mobile} {
    max-height: 12vw;
  }
}

.userInfo {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

:global {
  .ant-layout-header {
    display: none;
    background-color: #242119;
    --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07),
      0 2px 4px rgba(0, 0, 0, 0.05);
    --mdb-navbar-padding-top: 0.5625rem;
    --mdb-navbar-brand-img-margin-right: 0.25rem;
    box-shadow: var(--mdb-navbar-box-shadow);
    padding-top: var(--mdb-navbar-padding-top);
    line-height: normal !important;
    padding-inline: 1vw !important;
    flex-direction: row;
    z-index: 1000;

    @media #{$tabletPortrait} {
      display: flex;
      align-items: center;
      padding: 0;
    }
    @media #{$mobile} {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }

  .nav-icon {
    width: 3rem;
    height: 2.315rem;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 0.3rem;
      width: 100%;
      background: #ffffff;
      border-radius: 0.5rem;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      top: 1rem;
    }

    span:nth-child(4) {
      top: 2rem;
    }

    &.open {
      span:nth-child(1) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }
    }
  }

  .ant-layout-content {
    padding: 1.5rem;
    background: #f1f1f1;
    @media #{$mobile} {
      padding: 1.25rem;
    }
  }

  .ant-menu-dark {
    background: transparent;
    &:not(.ant-menu-horizontal)
      .ant-menu-item:not(.ant-menu-item-selected):hover {
      background: var(--bs-primary);
      // color: #000c17;
    }
  }

  .ant-layout {
    .ant-layout-sider {
      position: relative;
      width: 15vw !important;
      background-color: #242119;
      --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07),
        0 2px 4px rgba(0, 0, 0, 0.05);
      --mdb-navbar-padding-top: 0.5625rem;
      --mdb-navbar-brand-img-margin-right: 0.25rem;
      box-shadow: var(--mdb-navbar-box-shadow);
      z-index: 999;

      @media #{$tabletPortrait} {
        position: fixed;
        top: 0;
        width: 40vw !important;
        height: 100%;
        transform: translateX(-100%);

        &.show {
          transform: translateX(0);
        }
      }
      @media #{$mobile} {
        position: fixed;
        top: 0;
        width: 80vw !important;
        height: 100%;
        transform: translateX(-100%);

        &.show {
          transform: translateX(0);
        }
      }
    }
  }

  .menu-side {
    position: fixed;
    color: var(--bs-menu-side-color);
    width: 15vw;
    height: 100vh;
    overflow: hidden;
    background-color: var(--bs-menu-side-bg);

    @media #{$tabletPortrait} {
      width: 40vw;
      padding-top: 8vw;
    }
    @media #{$mobile} {
      width: 80vw;
      padding-top: 16vw;
    }

    .logo {
      @media #{$tabletPortrait} {
        display: none;
      }
    }

    a,
    ul,
    li {
      color: var(--bs-menu-side-color);
    }
    .ant-menu-inline .ant-menu-sub.ant-menu-inline {
      background-color: var(--bs-menu-side-sub-bg);
    }
    .ant-menu-submenu.ant-menu-submenu-selected {
      .ant-menu-submenu-title * {
        color: var(--bs-primary);
      }
    }

    .ant-menu .ant-menu-item.ant-menu-item-selected {
      background-color: transparent;
      color: var(--bs-primary);
      font-weight: bold;
    }

    .b-search {
      background-color: var(--btn-search-bg, #181611aa);
      border: none;
      color: var(--btn-search-color, #ffffff);
      text-align: left;
      input {
        background-color: transparent;
        color: #ffffff;
        pointer-events: none;
        &::placeholder {
          color: #ffffff;
          opacity: 0.5;
        }
      }
    }

    .bg-layer {
      height: 100%;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 104%;
      height: 104%;
      transform: translate(-50%, -50%);
      filter: blur(5px);
      background-color: #242119;
      background-image: url("../../../assets/images/bg-leftmenu.jpg");
      background-position: center center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      // z-index: 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #242119aa;
        // mix-blend-mode: color;
      }
    }
  }
}
