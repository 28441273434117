:global {
    .input-inline-api {
        .text-input-value {
            cursor: pointer;
            padding: 0.5rem;
            &:hover {
                background-color: rgb(211, 210, 210);
            }
        }
    }
}