@import "../../../assets/styles/variables";
:global {
  .cal-option {
    width: 300px;
    text-align: center;
    cursor: pointer;
    border-width: 2px;
    box-shadow: 0px 0.5rem 1rem rgba(179, 169, 148, 0.3);
    transition: 0.3s all ease-in-out;
    // border-radius: 0 0 8px 8px;
    // .anticon {
    //   opacity: 0;
    //   position: absolute;
    //   top: 8px;
    //   right: 8px;
    //   color: #4096ff;
    //   transition: 0.3s all ease-in-out;
    // }

    &.selected {
      border-color: var(--bs-primary);
      background-color: rgba(var(--bs-primary-rgb), 0.2);

      .anticon {
        opacity: 1;
      }
    }
  }

  .push-hold-button {
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .push-hold-button:active,
  .push-hold-button:focus {
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .pushButton:active {
    transform: translateY(4px);
  }

  .custom-dxf-btn-group {
    .btn-custom-dxf {
      // background-color: white;
      // color: black;
      box-shadow: 0px 0.5rem 1rem rgba(179, 169, 148, 0.3);
      border: 1px solid black;
      &.selected {
        border-color: #facb0f;
        background-color: #fef2c3;
        cursor: not-allowed;
        .anticon {
          opacity: 1;
        }
      }

      &.not-selected {
        border-color: #fff1f0;
        background-color: #eee;
        cursor: pointer;
        .anticon {
          opacity: 1;
        }
      }
    }
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse {
    margin-bottom: 16px;
    background-color: #fff;
  }

  .panel-part {
    background: #fffdf0;

    &.calculated {
      background: #f6ffed;
    }
    &.error {
      background: #fff1f0;
    }
    .footer-part {
      background-color: rgba(0, 0, 0, 0.02);
      padding: 12px 16px;
    }
    .ant-collapse {
      margin-bottom: 0;
      &.ant-collapse-ghost {
        .ant-collapse-header {
          padding-left: 0;
        }
      }
    }
  }

  .selectMaterial {
    width: 46vw !important;
    @media #{$tabletPortrait} {
      width: 100% !important;
    }
    .ant-modal-content {
      padding: 0;
      overflow: hidden;
    }
    .group {
      background: #f4f4f4;
      max-height: 78vh;
      overflow-y: auto;

      .group-menu {
        padding: 0 12px 12px 12px;
      }
    }
    .material-list {
      max-height: 70vh;
      overflow-y: auto;
      margin: 24px 0;
    }
    .group-item {
      margin-bottom: 16px;
    }
    .material-item {
      width: 100%;
      margin: 0 0 8px 0;
      border: 1px solid #eaeaea;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #fff8ec;
      }

      div {
        padding: 8px;
        border-right: 1px solid #eaeaea;
        &:nth-last-child(1) {
          border: none;
          color: #0378e355;
          b {
            color: #0378e3;
          }
        }
      }
    }
  }

  .calculation-done {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .form-quantity {
    .ant-input-number-group {
      .ant-input-number-group-addon {
        padding: 0;
        background-color: #f1f1f1;
      }
    }
    .ant-input-number-compact-item {
      height: 100%;
    }
  }

  .summary-price {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > div {
      align-items: center;
      label {
        &:first-child {
          color: #6b624c;
        }
        &:last-child {
          color: #4d4637;
          font-weight: 600;
        }
      }

      &.shipping-cost {
        label {
          &:first-child {
            color: #f7766c;
          }
          &:last-child {
            color: #d8382c;
          }
        }
      }

      .total-price {
        font-weight: 700;
        font-size: 1.5rem;
      }
    }
  }

  // .btn-custom-dxf {
  //   width: 180px;
  // }

  .b-container-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 1.275rem;
    background-color: #ffffff;
    box-shadow: 0px 0rem 1.25rem rgba(179, 169, 148, 0.4);
  }
  .s2-parts {
    padding-bottom: 2.5rem;
  }

  .file-mapping {
    position: relative;
    overflow: hidden;
    width: 50vw;
    margin-bottom: 1.5rem;
    padding: 0rem 0.5rem;
    box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
    background-color: #fff;
    border-radius: 5px;
    height: 4vw;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--bs-primary);
    }

    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }
}
