@import "../../../assets/styles/_variables.scss";

:global {
  .box-locale {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }
}
.auth {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  // background-image: url(../../../assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto 100%;
}

.logo {
  margin-top: 2vw;
  max-height: 8vw;

  @media #{$mobile} {
    width: 70vw;
    margin-top: 0;
    max-height: 28vw;
  }
}

.bgLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 40rem;
  height: 100%;
  background-color: #072a38;

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$tabletPortrait} {
    width: 40vw;
  }

  @media #{$mobile} {
    display: none;
  }

  object {
    width: 40vw;
    height: auto;
    margin-right: -14rem;
  }
}
